import type { RouteData } from 'routes';
import type { AbilityTo, ShopAccountType } from './constants';
import type { User, Abilities } from './types';

type AuthData = {
  email: string;
  password: string;
};

type RegistrationData = {
  email: string;
  password: string;
  confirmPassword: string;
  captchaToken?: string;
  customerData: Record<string, any>;
};

export const USER_LOGIN = 'USER_LOGIN' as const;
export const login = (authData: AuthData, redirectTo?: RouteData) => ({ type: USER_LOGIN, payload: { authData, redirectTo } });

export const LOGIN_FAILED = 'USER_LOGIN_FAILED' as const;
export const loginFailed = () => ({ type: LOGIN_FAILED });

export const LOGIN_FAILED_RESET = 'USER_LOGIN_FAILED_RESET' as const;
export const resetLoginFailed = () => ({ type: LOGIN_FAILED_RESET });

export const USER_REGISTER = 'USER_REGISTER' as const;
export const register = (registrationData: RegistrationData, updatedById: string) => ({ type: USER_REGISTER, payload: { registrationData, updatedById } });

export const USER_CREATE_PROSPECT = 'USER_CREATE_PROSPECT' as const;
export const createProspect = (prospectData: Record<string, any>, updatedById: string) => ({
  type: USER_CREATE_PROSPECT,
  payload: { prospectData, updatedById },
});

export const USER_CREATE_PROSPECT_PROCESSED = 'USER_CREATE_PROSPECT_PROCESSED' as const;
export const createProspectProcessed = (updatedById: string) => ({
  type: USER_CREATE_PROSPECT_PROCESSED,
  payload: { updatedById },
});

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED' as const;
export const authenticated = (user: User) => ({ type: USER_AUTHENTICATED, payload: user });
export type UserAuthenticatedAction = ReturnType<typeof authenticated>;

export const USER_LOGOUT = 'USER_LOGOUT' as const;
export const logout = () => ({ type: USER_LOGOUT });

export const USER_ABILITIES_REQUESTED = 'USER_ABILITIES_REQUESTED' as const;
export const loadUserAbilities = (keys: AbilityTo[]) => ({ type: USER_ABILITIES_REQUESTED, payload: keys });

export const USER_ABILITIES_LOADED = 'USER_ABILITIES_LOADED' as const;
export const userAbilitiesLoaded = (userAbilities: Abilities) => ({ type: USER_ABILITIES_LOADED, payload: userAbilities });
export type UserAbilitiesLoadedAction = ReturnType<typeof userAbilitiesLoaded>;

export const REPRESENT_CUSTOMER = 'USER_REPRESENT' as const;
export const representCustomer = (id: string, shopAccountType: ShopAccountType) => ({
  type: REPRESENT_CUSTOMER,
  payload: { id, shopAccountType, redirectBack: true },
});
export const stopRepresentingCustomer = () => ({ type: REPRESENT_CUSTOMER, payload: { id: null, shopAccountType: undefined, redirectBack: false } });

export const IMPERSONATION_FAILED = 'IMPERSONATION_FAILED' as const;
export const impersonationFailed = () => ({ type: IMPERSONATION_FAILED });

export const IMPERSONATION_FAILED_RESET = 'IMPERSONATION_FAILED_RESET' as const;
export const resetImpersonationFailed = () => ({ type: IMPERSONATION_FAILED_RESET });

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED' as const;
export const userProfileUpdated = (user: User) => ({ type: USER_PROFILE_UPDATED, payload: user });
export type UserProfileUpdatedAction = ReturnType<typeof userProfileUpdated>;

export const USER_RELOAD = 'USER_RELOAD' as const;
export const reloadUser = () => ({ type: USER_RELOAD });

export type UserAction = ReturnType<
  | typeof login
  | typeof loginFailed
  | typeof resetLoginFailed
  | typeof register
  | typeof createProspect
  | typeof createProspectProcessed
  | typeof authenticated
  | typeof logout
  | typeof loadUserAbilities
  | typeof userAbilitiesLoaded
  | typeof representCustomer
  | typeof stopRepresentingCustomer
  | typeof impersonationFailed
  | typeof resetImpersonationFailed
  | typeof userProfileUpdated
  | typeof reloadUser
>;
