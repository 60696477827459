import type { Action } from 'redux';
import type { Handler } from 'behavior/pages/types';
import type { ReceivedBasket, SalesAgreementInfoData } from 'behavior/basket/types';
import type { Mandatory } from 'utils/types';
import { getLoadBasketPageQuery } from './queries';
import { initComponent } from 'behavior/pages/helpers';
import { map, filter, pluck } from 'rxjs/operators';
import { requestBasketPage, basketReceived, CreditLimitValidationResult } from 'behavior/basket';
import { of, Observable } from 'rxjs';
import { pageSize } from 'behavior/basket/queries';
import { PageComponentNames } from '../componentNames';
import { getCorrectPageIndex, redirectToPage } from 'behavior/basket/helpers';
import { initSystemPageContent, SystemPage, SystemPageData, initPageContent } from '../system';
import { Presets } from './constants';
import { catchBasketCalculationError } from 'behavior/errorHandling';
import { setErrorMode } from 'behavior/basket/actions.errorMode';
import { RouteName } from 'routes';

const handler: Handler<BasketRouteData, BasketPage> = ({ params, options }, state$, { api, logger }) => {
  const pageIndex = getPageIndex(params);

  if (params?.previewToken) {
    return api.graphApi<BasketPageResponse>(getLoadBasketPageQuery(false)).pipe(
      pluck('pages', 'basket'),
      filter((basket: BasketPageData | null): basket is BasketPageData => !!basket),
      initComponent(PageComponentNames.Basket),
      initSystemPageContent(),
      map(({ page }) => ({
        page,
        action$: of(basketReceived({
          id: '',
          editDocumentId: null,
          editDocumentType: null,
          isBlocked: false,
          nonOrderableLines: null,
          totalCount: 3,
          productLines: {
            totalCount: 3,
            list: Array.from(Array(3)).map((_, index) => ({
              id: index.toString(),
              quantity: 1,
              product: { id: (index + 1).toString(), title: null, url: null, image: null, images: null, uoms: null },
              price: null,
              uom: null,
              discount: null,
              subTotal: null,
              salesAgreementLineId: null,
              availableSalesAgreementLines: null,
              serviceLines: [],
              extendedTexts: null,
              isSupplementary: false,
              subLines: null,
            })),
          },
          serviceLines: [],
          totals: { sub: 0, roundOff: 0, priceExcludingTax: 0, price: 0, prepayment: 0 },
          shippingCost: { price: 0 },
          paymentCost: { price: null },
          discount: { invoice: 0, payment: 0, promotion: null },
          tax: { amount: 0, taxes: null },
          creditLimit: { exceededAmount: null, validationResult: CreditLimitValidationResult.Valid },
          page: { index: 0, size: pageSize },
        }, null, pageIndex)),
      })),
    );
  }

  if (options) {
    const page = state$.value.page as BasketPage;
    if (options.action)
      return of({ action$: of(options.action), page });

    if (options.linesOnly) {
      return of({
        action$: of(requestBasketPage(pageIndex, page.preset === Presets.B2C)),
        page,
      });
    }
  }

  return api.graphApi<BasketPageResponse>(getLoadBasketPageQuery(), {
    index: pageIndex,
    loadCategories: !!state$.value.analytics?.isTrackingEnabled,
  }, { retries: 0 }).pipe(
    filter(data => !!data.pages.basket),
    map(data => {
      const { linesB2C, linesB2B, salesAgreementInfo, ...basket } = data.basket as {
        linesB2C: BasketProductLines;
        linesB2B: BasketProductLines;
        salesAgreementInfo: SalesAgreementInfoData;
      } & Omit<BasketDetails, 'salesAgreementInfo'>;
      basket.productLines = (linesB2C || linesB2B)!;
      const correctedPageIndex = getCorrectPageIndex(pageIndex, pageSize, basket.productLines.totalCount);

      let action$: Observable<Action>;
      if (correctedPageIndex !== pageIndex) {
        action$ = of(redirectToPage(state$.value.routing.location!.pathname, correctedPageIndex, false));
      } else {
        const basketReceivedAction = basketReceived(basket, salesAgreementInfo, pageIndex);
        action$ = state$.value.basket.isErrorMode ? of(basketReceivedAction, setErrorMode(false)) : of(basketReceivedAction);
      }

      return {
        page: {
          ...initPageContent(data.pages.basket!),
          component: PageComponentNames.Basket as const,
        },
        action$,
      };
    }),
    catchBasketCalculationError(error => {
      logger.error('The following error occurred:', error);
      const page = (error.response as { data: Omit<BasketPageResponse, 'basket'> }).data.pages.basket;
      return page
        ? of({
          page: { ...initPageContent(page), component: PageComponentNames.Basket as const },
          action$: of(setErrorMode(true)),
        })
        : of({ page });
    }),
  );
};

export default handler;

function getPageIndex(params: BasketRouteData['params']) {
  let pageIndex = 0;
  if (params?.page != null)
    pageIndex = params.page - 1; // Numbering in URL starts from 1.

  return pageIndex;
}

type BasketRouteData = {
  routeName: RouteName.BasketPage;
  params?: {
    previewToken?: string;
    page?: number;
  };
  options?: {
    action?: Action;
    linesOnly?: boolean;
  };
};

type BasketPage = SystemPage & {
  component: PageComponentNames.Basket;
  preset: Presets;
};

type BasketPageData = SystemPageData & {
  preset: Presets;
};

type BasketDetails = Mandatory<ReceivedBasket, 'isAvailable'> & {
  salesAgreementInfo: SalesAgreementInfoData;
};

type BasketProductLines = BasketDetails['productLines'];

type BasketPageResponse = {
  pages: {
    basket: BasketPageData | null;
  };
  basket: Omit<BasketDetails, 'productLines'> & {
    linesB2C?: BasketProductLines;
    linesB2B?: BasketProductLines;
  };
};
