import type { SystemPage, SystemPageData } from 'behavior/pages/system';
import type { Product, ProductListParams, ProductListPageBase, Facet, ListPreset } from '../types';
import type { LoadedSettings } from 'behavior/settings';
import type { AppState } from 'behavior';
import type { Handler } from 'behavior/pages/types';
import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createLoadOptions } from '../helpers';
import { searchPageQuery, searchPreviewPageQuery, getProductsQuery } from './queries';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { productsGeneralInfoLoaded } from '../actions';
import { loadSystemPageQuery, initPageContent } from 'behavior/pages/system';
import { RouteName } from 'routes/RouteName';

type LoadedAppState = AppState & {
  settings: LoadedSettings;
  analytics: NonNullable<AppState['analytics']>;
};

const handler: Handler<PageRouteData, Page> = ({ params, options }, state$, { api }) => {
  const handle = ({ settings }: LoadedAppState) => {
    const { showThumbnails } = settings.search;
    const { defaultViewMode, viewModeSwitchEnabled, pagingType } = settings.productList;
    const { sort, viewMode, previewToken } = params;
    const selectedViewMode = viewMode || defaultViewMode;

    if (previewToken) {
      return api.graphApi<PageResponse>(searchPreviewPageQuery).pipe(
        map(({ pages: { search: page }, settings: { search: { preset } } }) => !page ? null : ({
          page: {
            ...initPageContent(page),
            component: PageComponentNames.Search as const,
            id: 'search',
            viewModeSwitchEnabled,
            showThumbnails,
            pagingType,
            selectedSorting: sort,
            defaultViewMode,
            selectedViewMode,
            lastViewedEnabled: false,
            relevanceAvailable: true,
            products: Array.from(Array(3)).map((_, index) => ({
              id: (index + 1).toString(),
              url: '',
              title: '',
              isOrderable: true,
              calculated: true,
              productConfiguratorInfo: {},
              image: null,
              hasVariants: null,
              uom: null,
              uoms: null,
              stockLevels: null,
              specifications: [],
            })),
            totalCount: 3,
            preset,
          },
        })),
      );
    }

    const loadOptions = createLoadOptions(params, { ...options, viewMode: selectedViewMode }, settings.productList);

    if (!loadOptions.keywords) {
      return api.graphApi<PageResponse>(loadSystemPageQuery('search')).pipe(
        map(({ pages: { search: page } }) => !page ? null : ({
          page: {
            ...initPageContent(page),
            id: 'search',
            products: [],
            totalCount: 0,
            showThumbnails,
            viewModeSwitchEnabled,
            pagingType,
            relevanceAvailable: false,
            lastViewedEnabled: false,
            selectedViewMode,
            defaultViewMode,
            preset: null,
            component: PageComponentNames.Search as const,
          },
        })),
      );
    }

    const state = state$.value as LoadedAppState;
    const apiOptions = {
      options: loadOptions,
      loadLargeImages: false,
      loadCategories: state.analytics?.isTrackingEnabled,
    };

    const queryFactoryOptions = {
      isInsiteEditor: state.insiteEditor.initialized,
      isProductGroupingEnabled: state.settings.product.productGrouping.isEnabled,
    };

    if (options?.productsOnly) {
      return api.graphApi<PageResponse>(getProductsQuery(queryFactoryOptions), apiOptions).pipe(
        map(({ catalog: { products: foundProducts } }) => {
          if (!foundProducts)
            return null;

          const { products, totalCount } = foundProducts;
          const appendProducts = options.appendProducts;
          const size = loadOptions.page.size;

          return {
            page: state.page as Page,
            action$: of(productsGeneralInfoLoaded(products, appendProducts, size || products.length, totalCount, sort)),
          };
        }),
      );
    }

    return api.graphApi<PageResponse>(searchPageQuery(queryFactoryOptions), apiOptions).pipe(
      map(({ pages: { search: page }, catalog: { products: foundProducts }, settings: { search: { preset } } }) => {
        if (!page || !foundProducts)
          return null;

        const { products, totalCount, facets } = foundProducts;

        return {
          page: {
            ...initPageContent(page),
            id: 'search',
            products,
            totalCount,
            facets,
            viewModeSwitchEnabled,
            showThumbnails,
            pagingType,
            selectedSorting: sort,
            defaultViewMode,
            selectedViewMode,
            lastViewedEnabled: false,
            relevanceAvailable: true,
            component: PageComponentNames.Search as const,
            preset,
          },
        };
      }),
    );
  };

  return state$.pipe(
    first(isStateLoaded),
    switchMap(handle),
  );
};

export default handler;

function isStateLoaded(state: AppState): state is LoadedAppState {
  return state.settings.loaded && !!state.analytics;
}

type PageResponse = {
  pages: {
    search: SystemPageData;
  };
  catalog: {
    products: {
      products: Product[];
      totalCount: number;
      facets: {
        facets: Facet[];
        multiSelect: boolean;
      } | null;
    } | null;
  };
  settings: {
    search: {
      preset: ListPreset | null;
    };
  };
};

type PageRouteData = {
  routeName: RouteName.Search;
  params: {
    previewToken?: string;
  } & ProductListParams;
  options?: {
    productsOnly?: boolean;
    appendProducts: boolean;
  };
};

export type Page = SystemPage & ProductListPageBase & {
  component: PageComponentNames.Search;
  relevanceAvailable: boolean;
};
